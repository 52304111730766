'use client';

import Image from 'next/image';
import Trane from '@/public/img/brands/Logo_Trane.png';
import Logo_AmericanStandard from '@/public/img/brands/Logo_AmericanStandard.png';
import RunTru from '@/public/img/brands/Logo_RunTru.png';
import Ameristar from '@/public/img/brands/Logo_Ameristar.png';
import TraneGrayscale from '@/public/img/brands/Logo_Trane_grayscale.png';
import Logo_AmericanStandardGrayscale from '@/public/img/brands/Logo_AmericanStandard_grayscale.png';
import RunTruGrayscale from '@/public/img/brands/Logo_RunTru_grayscale.png';
import AmeristarGrayscale from '@/public/img/brands/Logo_Ameristar_grayscale.png';

export interface BrandLogosProps {
	testId?: string;
	grayscale?: boolean;
}

const BrandLogos = (props: BrandLogosProps) => {
	const { testId = 'BrandLogos', grayscale = false } = props;

	const brandLogos = [
		{
			src: grayscale ? TraneGrayscale : Trane,
			alt: 'Trane Logo',
		},
		{
			src: grayscale ? Logo_AmericanStandardGrayscale : Logo_AmericanStandard,
			alt: 'American Standard Logo',
		},
		{
			src: grayscale ? RunTruGrayscale : RunTru,
			alt: 'RunTru Logo',
		},
		{
			src: grayscale ? AmeristarGrayscale : Ameristar,
			alt: 'Ameristar Logo',
		},
	];

	return (
		<div
			data-testid={testId}
			className="-mt-14 mb-10 bg-slate-50 py-6 lg:-mt-16 lg:mb-20"
		>
			<p className="mb-3 mt-0 text-center text-sm font-semibold text-slate-500 lg:mb-2 lg:text-lg">
				Our installers use top quality brands like
			</p>
			<div className="mx-auto grid max-w-85 grid-cols-2 place-items-center items-center gap-4 md:max-w-5xl md:grid-cols-4 md:px-6">
				{brandLogos.map((logo) => (
					<Image
						key={logo.alt}
						src={logo.src}
						alt={logo.alt}
						width={192}
						height={64}
					/>
				))}
			</div>
		</div>
	);
};

export default BrandLogos;
